@import "assets/styles/mixin.scss";
@import "assets/styles/variable.scss";

.root {
  background-color: #000000e0;
  padding: $space-2xl $space-c-x $space-l;
  color: $color-p-white;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  display: flex;
  flex-direction: column;

  @include mq(tablet-xl) {
    gap: $space-m;
    justify-content: space-between;
    flex-direction: row;
  }

  p {
    font-size: 1.1rem;
  }

  a {
    &:hover {
      color: $color-p-yellow;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
  }
}

.logo-wrapper {
  width: 150px;

  @include mq(tablet-xl) {
    width: 200px;
  }
}

.left-content {
  img {
    transition: transform 0.2s ease-out;
    max-width: 36px;

    @include mq(desktop) {
      max-width: 52px;
    }
  }

  a:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.copyright {
  text-align: center;
  padding-top: $space-xl;
  margin-top: $space-m;
}
