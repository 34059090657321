// this define the global style AND it should be inlcude only in index.scss

@import "./variable.scss";
@import "./mixin.scss";
@import "./spacing.scss";
@import "./font-system.scss";
@import "./reset.scss";
@import "./default.scss";

// 3rd import
@import "react-datepicker/dist/react-datepicker.css";

// app import
@import "./ck-editor.scss";
@import "./animation.scss";
@import "./app.scss";

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  color: $color-p-black;

  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: rgb(0, 0, 0);
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffcc01;
    border-radius: 10px;
  }
}

// root font system
html {
  @include interpolate-font-system;
}

h3 {
  @extend .text-h3;
}

button {
  font-family: "Montserrat", sans-serif;
}

.d-none,
.hidden {
  display: none !important;
}

.d-block {
  display: block !important;
}

.shadow-none {
  box-shadow: none !important;
}

.user-select-none {
  user-select: none !important;
}

.opa-0 {
  opacity: 0 !important;
  pointer-events: none;
}

.opa-1 {
  opacity: 1 !important;
}

.visibility-hidden {
  visibility: hidden;
}

// media query class gen
$display-none: none !important;
$display-ok: var(--display-style, block);
// breakpoint class utils gen (only for expose = true)
// eg: tablet will gen with class: tablet, tablet-only and tablet-lt
@each $bp, $sizes in $break-points {
  $min: map-get($sizes, "min");
  $max: map-get($sizes, "max");
  $expose: map-get($sizes, "expose");

  @if $expose {
    .#{$bp} {
      display: $display-none;
      @include mq(#{$bp}) {
        display: $display-ok !important;
      }
    }

    @if $min != null {
      .#{$bp}-lt {
        display: $display-none;
        @include mq(#{$bp}-lt) {
          display: $display-ok !important;
        }
      }
    }

    @if $min != null and $max != null {
      .#{$bp}-only {
        display: $display-none;
        @include mq(#{$bp}-only) {
          display: $display-ok !important;
        }
      }
    }
  }
}

// spacing util class gen
@each $space in $spacing-list {
  .p-#{$space} {
    padding: var(--space-#{$space});
  }
  .pt-#{$space} {
    padding-top: var(--space-#{$space});
  }
  .pb-#{$space} {
    padding-bottom: var(--space-#{$space});
  }
  .pl-#{$space} {
    padding-left: var(--space-#{$space});
  }
  .pr-#{$space} {
    padding-right: var(--space-#{$space});
  }
  .m-#{$space} {
    margin: var(--space-#{$space});
  }
  .mt-#{$space} {
    margin-top: var(--space-#{$space});
  }
  .mb-#{$space} {
    margin-bottom: var(--space-#{$space});
  }
  .ml-#{$space} {
    margin-left: var(--space-#{$space});
  }
  .mr-#{$space} {
    margin-right: var(--space-#{$space});
  }
}

// React Slick styles

.slick-slide {
  display: flex !important;
  justify-content: center;
}

.slick-arrow {
  z-index: 1;
  width: 4rem;
  height: 2rem;
}

.slick-next:before,
.slick-prev:before {
  color: #000000;
  font-size: 2rem;

  @include mq(desktop-xl) {
    font-size: 3rem;
  }
}

.slick-dots {
  position: unset;
  margin: 2rem 0;
}

.slick-dots li {
  margin: 2px;

  .slick-active {
    button {
      &::before {
        color: $color-p-yellow !important;
      }
    }
  }
}

.slick-dots li button:before {
  font-size: 1rem;
}

.slick-prev,
.slick-next {
  top: 42%;
}

.slick-prev {
  left: 0%;
}

.slick-next {
  right: 0%;
}

.slick-dots li.slick-active button:before {
  color: $color-p-yellow;
}
