@import "./utils.scss";

// color
$color-p-blue: #0095f3;
$color-p-grey: #8d9193;
$color-p-black: #000000;
$color-p-white: #ffffff;
$color-p-yellow: #ffcc01;

$color-s-pale-blue: #00d0f2;
$color-s-pink: #ff006c;
$color-s-orange: #ff9a00;

// primary blue is 50
$color-p-blue-20: adjust-color($color-p-blue, $lightness: -30%);
$color-p-blue-30: adjust-color($color-p-blue, $lightness: -20%);
$color-p-blue-40: adjust-color($color-p-blue, $lightness: -10%);
$color-p-blue-50: adjust-color($color-p-blue, $lightness: 0%);
$color-p-blue-60: adjust-color($color-p-blue, $lightness: +10%);
$color-p-blue-70: adjust-color($color-p-blue, $lightness: +20%);
$color-p-blue-80: adjust-color($color-p-blue, $lightness: +30%);
$color-p-blue-90: adjust-color($color-p-blue, $lightness: +40%);
// primary black is 10
$color-p-black-10: adjust-color($color-p-black, $lightness: 0%);
$color-p-black-20: adjust-color($color-p-black, $lightness: +10%);
$color-p-black-30: adjust-color($color-p-black, $lightness: +20%);
$color-p-black-40: adjust-color($color-p-black, $lightness: +30%);
$color-p-black-50: adjust-color($color-p-black, $lightness: +40%);
$color-p-black-60: adjust-color($color-p-black, $lightness: +50%);
$color-p-black-70: adjust-color($color-p-black, $lightness: +60%);
$color-p-black-80: adjust-color($color-p-black, $lightness: +70%);
$color-p-black-90: adjust-color($color-p-black, $lightness: +80%);
// secondary pink is 10
$color-s-pink-10: adjust-color($color-s-pink, $lightness: 0%);
$color-s-pink-20: adjust-color($color-s-pink, $lightness: +20%);
$color-s-pink-30: adjust-color($color-s-pink, $lightness: +30%);
$color-s-pink-40: adjust-color($color-s-pink, $lightness: +40%);
// secondary orange is 10
$color-s-orange-40: adjust-color($color-s-orange, $lightness: +30%);

// spacing
$container-w: var(--container-w);
$space-c-x: var(--space-c-x);
$space-c-t: var(--space-c-t);
$space-c-b: var(--space-c-b);

$space-3xs: var(--space-3xs);
$space-2xs: var(--space-2xs);
$space-xs: var(--space-xs);
$space-s: var(--space-s);
$space-m: var(--space-m);
$space-l: var(--space-l);
$space-xl: var(--space-xl);
$space-2xl: var(--space-2xl);
$space-3xl: var(--space-3xl);
$space-4xl: var(--space-4xl);

// system
$fz-base-large: 16;
$fz-base-medium: 15;
$fz-base-small: 15;

// convert px to rem for large screen
@function lrem($value) {
  $unit: unit($value);

  @if (not $unit or $unit != px) {
    @error 'value should be px';
  }
  @return toFixed($value / $fz-base-large) * 1rem;
}

// convert px to rem for small screen
@function srem($value) {
  $unit: unit($value);

  @if (not $unit or $unit != px) {
    @error 'value should be px';
  }
  @return toFixed($value / $fz-base-small) * 1rem;
}

// convert px to rem for medium screen
@function mrem($value) {
  $unit: unit($value);

  @if (not $unit or $unit != px) {
    @error 'value should be px';
  }
  @return toFixed($value / $fz-base-medium) * 1rem;
}
