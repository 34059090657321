@import "assets/styles/mixin.scss";
@import "assets/styles/variable.scss";

.root {
  height: 5rem;
  background-color: #000000e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem 0 $space-c-x;
  box-shadow: 0 4px 4px #00000040;
  position: fixed;
  width: 100%;
  z-index: 2;
  gap: $space-m;

  @include mq(tablet) {
    padding-right: 8rem;
  }
}

.logo-wrapper {
  max-width: 80px;

  @include mq(tablet) {
    max-width: 120px;
  }

  @include mq(desktop) {
    max-width: 130px;
  }
}

.menu-right {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @include mq(desktop-l) {
    gap: 1rem;
  }
}

.easylaser-logo {
  @include mq(tablet) {
    padding-right: 2rem;
  }
}

.sticky-buttons {
  position: fixed;
  right: 20px;
  bottom: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  z-index: 10;

  @include mq(tablet) {
    right: 50px;
    bottom: 35px;
  }
}

.sticky-button {
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;

  &:hover {
    transform: translateY(-10px) scale(1.1);
  }
}

.gotop-btn {
  width: 36px;
  height: 36px;

  @include mq(desktop) {
    width: 40px;
    height: 40px;
  }
}
