@import "assets/styles/mixin.scss";
@import "assets/styles/variable.scss";

.root {
  position: relative;
  color: $color-p-white;

  @include mq(desktop) {
    padding: 0;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.container {
  position: relative;
}

.selected-value {
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 38px;
  white-space: nowrap;

  @include mq(desktop-xl) {
    height: 50px;
  }

  &:hover {
    color: $color-p-yellow;

    .arrow > path {
      stroke: $color-p-yellow;
    }
  }

  .language-selected {
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
  }
}

.arrow {
  position: relative;
  width: 6px;
  height: 12px;
  transform: rotateZ(90deg);
  transition: transform 0.2s ease;
  color: $color-p-white;
  margin-left: 0.5rem;

  &--open {
    transform: rotateZ(-90deg);
  }
}

.dropdown {
  position: absolute;
  top: 110%;
  min-width: 100%;
  width: fit-content;
  background-color: #fff;
  color: #000;

  z-index: 3;
  opacity: 0;
  max-width: 400px;
  overflow-y: auto;
  pointer-events: none;
  transition: opacity 0.2s ease;
  box-shadow: 12px 0px 20px rgba(106, 115, 129, 0.22);
  border-radius: 8px;

  @include mq(desktop-l) {
    right: -100%;
    color: #fff;
    background-color: #000000a8;
  }

  &--expand {
    opacity: 1;
    pointer-events: initial;
  }
}

.options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $space-xs;
  width: fit-content;
  max-height: 250px;
  min-width: 100%;
}

.option {
  user-select: none;
  width: 100%;
  white-space: nowrap;

  &--clickable {
    cursor: pointer;
    pointer-events: initial;
  }

  &:hover {
    color: $color-p-yellow;
  }
}

.language-option {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border-radius: 3px;
  padding: $space-xs;

  &-icon {
    border-radius: 3.5px;
    width: 36px;
    height: auto;
  }

  .language-option-text {
    margin-top: 0.15em;
    font-weight: 600;
  }
}
