@import "assets/styles/mixin.scss";
@import "assets/styles/variable.scss";

.root {
  position: fixed;
  bottom: 1%;
  left: 1%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;

  @include mq(tablet) {
    width: 70px;
    height: 70px;
  }

  &:hover {
    .icon-wrapper {
      transform: scale(1.1);
    }

    .wave {
      animation-play-state: paused;
    }
  }
}

.icon-wrapper {
  padding: 1rem;
  transition: 0.2s ease transform;
  display: flex;
}

.wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: #2e65fe;
  z-index: -1;
  pointer-events: none;
  animation: wave 2s linear infinite;
}

@keyframes wave {
  from {
    opacity: 0.5;
    transform: scale(0.8);
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
}
