@import "assets/styles/mixin.scss";
@import "assets/styles/variable.scss";

.root {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20%;
  height: 100%;

  @include mq(tablet) {
    width: auto;
  }

  svg {
    width: 26px;
    height: 26px;
  }
}

.search-form {
  transform: translateX(-2rem);
  position: absolute;
  display: flex;
  align-items: center;
}

.search-input {
  width: 17rem;
  display: flex;
  align-items: center;
}

.input {
  font-size: 1.2rem;
  padding: 0.5rem 40px 0.5rem 0.5rem;
  border: 1px solid black;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease;

  &:focus {
    border: 1px solid $color-p-black;
    box-sizing: border-box;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  &-hide {
    min-height: 0;
    height: 0;
    padding: 0;
    color: transparent;
    border-color: transparent !important;
    transition: border-color 0s linear;
    border: none !important;
  }
}

.icon {
  position: absolute;
  display: flex;
  width: 1.4em;
  max-width: 21px;
  left: 10px;
  cursor: pointer;

  > * {
    width: 100%;
    height: auto;
  }

  &:hover {
    path {
      fill: $color-p-yellow;
    }
  }
}

.icon-focus {
  svg > path {
    fill: gray;
  }
  &:hover {
    path {
      fill: gray;
    }
  }
}

.clear {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: $color-p-black-50;
  cursor: pointer;
}
