.box-ratio {
  position: relative;
  display: flex;

  &__content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
