@import "assets/styles/mixin.scss";
@import "assets/styles/variable.scss";

.root {
  height: 100vh;
  position: relative;
  padding: $space-2xl $space-c-x;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.back-home {
  align-self: flex-start;
  font-size: 1.2rem;
  text-decoration: underline;
  padding-top: 1rem;
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
