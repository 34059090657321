@import "assets/styles/mixin.scss";
@import "assets/styles/variable.scss";

.root {
  margin: 0;
  display: none;
  gap: $space-l;
  flex: 1;
  align-items: center;
  justify-content: flex-start;

  @include mq(desktop-l) {
    display: flex;
  }
}

.top-link {
  padding: 6px 0;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1rem;
  color: $color-p-white;
  cursor: auto;
  font-style: normal;
  transition: 0.3s ease color;

  &:hover {
    color: $color-p-yellow;
  }

  &--selected {
    color: $color-p-yellow;
  }
}

.link-groupl1 {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover .groupl1-content {
    pointer-events: initial;
    opacity: 1;
  }
  &:hover .link-arrowl1 {
    transform: rotate(90deg) scale(-1);
  }
}

.is-active {
  color: #ffcc01;
}

.link-arrow {
  transform: rotate(90deg);
  height: 1em;
  margin-left: $space-xs;
  transition: transform 0.2s;
  path {
    stroke-width: 2px;
  }

  &--expanded {
    transform: rotate(90deg) scale(-1);
  }
}

.groupl1-content {
  display: block;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  min-width: 282px;
  padding: $space-s $space-m;
  background: #000000a8;
  box-shadow: 12px 0px 20px rgba(106, 115, 129, 0.22);
  border-radius: 8px;
  transition: opacity 0.2s;
}
