.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;

  &__mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90vw;
  }

  &__back-button {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
}
