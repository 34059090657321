@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.anim-spin {
  animation: spin 0.75s linear infinite reverse;
}
