@import "./breakpoints.scss";

// shadow
@mixin shadow-low {
  box-shadow: 0px 2px 8px 0px #0000001a;
}
@mixin shadow-med {
  box-shadow: 0px 3px 8px 0px #0000001a;
}
@mixin shadow-high {
  box-shadow: 0px 12px 20px 0px #0000001a;
}
@mixin shadow-top {
  box-shadow: 0px -2px 8px 0px #0000001a;
}
@mixin shadow-left {
  box-shadow: -1px 2px 8px 0px #0000001a;
}
@mixin shadow-right {
  box-shadow: 1px 0px 8px 0px #0000001a;
}
@mixin elevation-1 {
  box-shadow: 0px 5px 8px 0px #0000001a;
}
@mixin elevation-2 {
  box-shadow: 0px 9px 10px 0px #0000001a;
}
@mixin elevation-3 {
  box-shadow: 0px 50px 50px 0px #0000001a;
}
