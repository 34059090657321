@import "assets/styles/mixin.scss";
@import "assets/styles/variable.scss";

// specific application style

// hide recaptcha3 badge
.grecaptcha-badge {
  visibility: hidden;
}

// default var
:root {
  // circle pagination default primary color
  --circle-background-color: #c4c4c4;
  --circle-fill-color: #{$color-p-blue};
  --circle-text-color: #{$color-p-white};
}

// section background
.section-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;

  img,
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: center;
  }
}

// from element
$form-text-color: #5f5f5f;
$form-boder-color: #3d3d3d;
$form-boder-color-dis: #dadada;
:root {
  --form-input-color: #{$form-text-color};
  --form-input-color-checkbox: #{$color-p-black};
  --form-input-color-error: #e25555;
  --form-input-color-dis: #{rgba(#5f5f5f, 0.5)};
  --form-input-color-border: #{$form-boder-color};
  --form-input-color-border-dis: #{$form-boder-color-dis};

  .css-th9yun-MuiButtonBase-root-MuiButton-root {
    font-weight: 700;
  }
}

// reset form auto fill
select:-webkit-autofill,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  // box-shadow: 0 0 0 30px white inset !important;
  // border-color: white !important;
  -webkit-text-fill-color: var(--form-input-color);
}

.form-input-field {
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 600;
  margin: 2em 0;
  color: var(--form-input-color);

  input {
    padding: 1.2em 1.4em;
    display: block;
    width: 100%;
    background: $color-p-white;
    border: 2px solid var(--form-input-color-border);
    color: currentColor;
    border-radius: 10px;
    outline: none;
    font-size: 1em;
    font: inherit;
    transition: box-shadow 0.1s linear, border-color 0.1s linear;
    &::placeholder {
      color: currentColor;
      font-size: 1em;
      font-weight: 600;
      font-style: italic;
      // opacity: 0.8;
    }

    &:hover {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }
    &:focus {
      border-color: $color-p-blue;
    }
  }

  // forward event for dropdown
  select:hover + input {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  select:focus + input {
    border-color: $color-p-blue;
  }

  .checkbox {
    font-weight: 400;
    font-size: 0.88rem;
    color: var(--form-input-color-checkbox);
  }

  .dropdown {
    input {
      padding-right: 1.7rem;
    }
  }

  &--error {
    input {
      border-color: var(--form-input-color-error);
    }
  }

  &--disabled {
    color: var(--form-input-color-dis);

    input {
      background-color: #fafafa;
      border-color: var(--form-input-color-border-dis);
      &:hover {
        box-shadow: none;
      }
      &:focus {
        border-color: var(--form-input-color-border-dis);
      }
    }
    select:hover + input {
      box-shadow: none;
    }
    .checkbox {
      color: var(--form-input-color-dis);

      .check-mark {
        border-color: var(--form-input-color-dis);
      }
    }
  }

  &__error {
    display: flex;
    align-items: center;
    position: absolute;
    color: var(--form-input-color-error);
    font-size: 10px;
    line-height: 1.2;
    font-style: italic;
    margin-top: 2px;

    &-icon {
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }
  }
}

//  hide error icon on checkbox
.form-input-checkbox {
  margin: 0.5em 0;
  &.form-input-field--error .check-mark {
    border-color: var(--form-input-color-error);
  }
  .form-input-field__error {
    position: static;
    margin-left: 1.6rem;
  }

  .form-input-field__error-icon {
    display: none;
  }
}

.form-group-row {
  @extend .form-input-field;
  display: flex;

  & > .form-input-field {
    flex: 1;
    margin: 0;
    margin-right: 10px;
  }

  & > .form-input-field:last-child {
    margin-right: 0;
  }
}

.form-group-column {
  @extend .form-input-field;
  display: block; //NOTE: flex can't use override vertical margin
  // flex-direction: column;

  & > .form-input-field:last-child {
    margin-bottom: 0;
  }
}

// override circle pagination
.com-pagination-circle__spin-text {
  @extend .text-small;
  font-weight: 500;
}

.com-pagination-number {
  @extend .text-body2;
}

// font system
@include font-system;

// shadow
.shadow-low {
  @include shadow-low;
}
.shadow-med {
  @include shadow-med;
}
.shadow-high {
  @include shadow-high;
}
.shadow-top {
  @include shadow-top;
}
.shadow-left {
  @include shadow-left;
}
.shadow-right {
  @include shadow-right;
}
.elevation-1 {
  @include elevation-1;
}
.elevation-2 {
  @include elevation-2;
}
.elevation-3 {
  @include elevation-3;
}
